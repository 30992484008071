import React from "react";
import EditCarteiras from "../componentes/editarCarteira";

const EditarCarteiras = () => {
  return (
    <>
    <EditCarteiras />
    </>
  )
}

export default EditarCarteiras;