import React, { useState } from 'react';

const Gavetas = () => {
  const [gavetaAberta, setGavetaAberta] = useState(1);

  const handleGavetaClick = (numeroGaveta) => {
    setGavetaAberta((prevGaveta) => (prevGaveta === numeroGaveta ? prevGaveta : numeroGaveta));
  };

  const gavetas = [
    { numero: 1, titulo: 'QUEM PODE EMITIR A CARTEIRA DE ESTUDANTE?', conteudo: 'O parágrafo segundo do artigo 1º da Lei Federal 12.933/13 sob os efeitos supressivos de medida cautelar da ADI 5108 do STF (Supremo Tribunal Federal), dá autonomia a qualquer entidade estudantil para a emissão de Carteiras de Estudante, sejam elas, diretórios ou centros acadêmicos, entidades municipais, estaduais ou nacionais, desde que sigam o PADRÃO NACIONAL e as especificações técnicas do ITI (Instituto Nacional de Tecnologia da Informação) para certificação de atributos.' },
    { numero: 2, titulo: 'ONDE POSSO USAR MINHA CARTEIRA?', conteudo: 'A carteira de estudante UNISC ESTUDANTIL deve ser usada somente para eventos culturais e esportivos, não podendo ser usada em transporte publico.' },
    { numero: 3, titulo: 'QUAL A VALIDADE DA CARTEIRA?', conteudo: 'A lei da meia entrada estabelece (Art. 1º paragrafo 6º) que a carteira de Identificação Estudantil (CIE) será válida da data de sua expedição até o dia 31 de março do ano subsequente' },
    { numero: 4, titulo: 'A UNISC TEM APLICATIVO?', conteudo: 'Não, a carteira UNISC Estudantil fica disponível somente através do site (https://www.uniscestudantil.org.br), não havendo outra maneira de consultar ou fazer download da carteira.' },
    { numero: 5, titulo: 'COMO FUNCIONA A CARTEIRA DIGITAL?', conteudo: `O download da carteira digital pode ser feito através do nosso site (https://www.uniscestudantil.org.br/), consulte sua carteira na seção 'VERIFIQUE SUA CARTEIRA' preenchendo o campo de consulta com CPF ou Código de uso.` },
  ];

  return (
    <div className='container_gavetas'>
      {gavetas.map(({ numero, titulo, conteudo }) => (
        <div
          key={numero}
          className={`gaveta ${gavetaAberta === numero ? 'aberto' : ''}`}
          onClick={() => handleGavetaClick(numero)}
        >
          <h3>{titulo}</h3>
          <div className={`conteudo ${gavetaAberta === numero ? 'aberto' : ''}`}>
            <p>{conteudo}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Gavetas;
