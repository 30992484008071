import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


const DeletarGestor = ({show, deletar, id, handleClose}) => {
return (
<Dialog
  open={show}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  >
  <DialogTitle> Deletar Gestor </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Deletar Permanentemente?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>NÃO</Button>
    <Button onClick={() => deletar(id)} autoFocus>
      SIM
    </Button>
  </DialogActions>
  </Dialog>)

}

export default DeletarGestor;