import React, { useState, useEffect, useCallback } from "react";
import { requestData, uploadImage } from "../services/requests";
import { cpfMask } from '../services/funcoesAuxiliares';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import api from "../services/requests";
import EditarImagem from "./EditarImagem";
import { SelectGestores } from "../styled/FiltrarDados";
import jwt_decode from "jwt-decode";
import { ContainerGlobal, Salvar, Voltar } from "../styled/EditarCarteiras";
import { Abrir } from "../styled/Carteiras";

const EditCarteiras = () => {
  const url_atual = window.location.href;
  const id = url_atual.split('/')[5];
  const user = JSON.parse(localStorage.getItem('user'));
  const { data } = jwt_decode(user);
  const [users, setUsers] = useState([]);
  const [nome, setNome] = useState("");
  const [instituicaoEnsino, setInstituicaoEnsino] = useState('');
  const [curso, setCurso] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [imagem, setImagem] = useState('');
  const [status, setSelectStatus] = useState('');
  const [foto, setFoto] = useState("");
  const [matricula, setMatricula] = useState('');
  const [gestorId, setGestorId] = useState([]);
  const navigate = useNavigate();

  const gestores = async () => {
    const endpoint = '/user';
    const gestores = await requestData(endpoint);
    setUsers(gestores);
  }

  const newArrayGestores = users
    .sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
    .map((user) => ({ label: user.name, value: user.name, id: user.id }))

  const getCarteira = useCallback(async () => {
    const carteiras = await requestData(`/carteiras/${id}`);
    setSelectStatus(carteiras.status);
    setFoto(carteiras.foto);
    setNome(carteiras.name);
    setInstituicaoEnsino(carteiras.instituicaoEnsino);
    setCurso(carteiras.curso);
    setCpf(carteiras.cpf);
    setRg(carteiras.rg);
    setGestorId([{ label: carteiras.gestorResponsavel.name, value: carteiras.gestorResponsavel.name, id: carteiras.gestorResponsavel.id }])
    setDataNascimento(carteiras.dataNascimento);
    setEmail(carteiras.email);
    setTelefone(carteiras.telefone);
    setMatricula(carteiras.matricula);
  }, [id]);

  useEffect(() => {
    getCarteira();
    gestores();
  }, [getCarteira]);

  const onChangeClick = async (status) => {
    setSelectStatus(status);
    try {
      await api.patch(`/carteiras/editar/status/${id}`, { status });
    } catch (error) {
      console.error(error);
    }
  };

  const editarCarteira = async (e) => {
    e.preventDefault();
    try {
      if (imagem !== "") {
        const upload = '/upload-image';
        const formData = new FormData();
        formData.append('image', imagem)
        await uploadImage(upload, formData);
      }

      await api.patch(`/carteiras/editar/${id}`, {
        foto: imagem === "" ? foto : `https://api.uniscestudantil.org.br/images/${imagem.name}`,
        nome,
        instituicaoEnsino,
        curso,
        cpf,
        rg,
        dataNascimento,
        email,
        telefone,
        matricula,
        user_id: gestorId[0].id
      });
      alert('Salvo com sucesso!');
    } catch (error) {
      console.log(error);
    }
  }

  const voltar = () => {
    navigate(`/painel`);
    window.reload();
  }

  const VisualizarCarteira = () => {
    navigate(`/carteiras/${id}`);
  }

  return (
    <>
      <ContainerGlobal>
        <div className="bodyFake">
          <EditarImagem img={imagem} setImagem={setImagem} imagemInicial={foto} />
          <form className="formEditarImagem">
            <div className="dadosAluno">
              <label htmlFor="nome"> Nome:
                <p>
                  <input
                    disabled={status === "Validada" || status === "Cancelada" ? true : false}
                    value={nome}
                    id="nome"
                    type="text"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                  />
                </p>
              </label>
              <label htmlFor="cpf"> CPF:
                <p>
                  <input
                    disabled={status === "Validada" || status === "Cancelada" ? true : false}
                    id="cpf"
                    value={cpf}
                    type="text"
                    onChange={(e) => setCpf(cpfMask(e.target.value))}
                  />
                </p>
              </label>
              <label htmlFor="RG"> RG:
                <p>  <input
                  disabled={status === "Validada" || status === "Cancelada" ? true : false}
                  id="RG"
                  type="text"
                  value={rg}
                  onChange={(e) => setRg(e.target.value)}
                />
                </p>
              </label>
              <label htmlFor="DataNascimento"> Nascimento:
                <p>
                  <InputMask
                    disabled={status === "Validada" ||  status === "Cancelada" ? true : false}
                    mask="99/99/9999"
                    id="DataNascimento"
                    type="text"
                    value={dataNascimento}
                    onChange={(e) => setDataNascimento(e.target.value)}
                  />
                </p>
              </label>
            </div>
            <div className="DadosInstituicao">
              <label htmlFor="InstEnsino"> Instituição:
                <p>  <input
                  disabled={status === "Validada" || status === "Cancelada" ? true : false}
                  id="InstEnsino"
                  value={instituicaoEnsino}
                  type="text"
                  onChange={(e) => setInstituicaoEnsino(e.target.value.toUpperCase())}
                />
                </p>
              </label>
              <label htmlFor="Curso"> Curso:
                <p>  <input
                  disabled={status === "Validada" || status === "Cancelada" ? true : false}
                  id="Curso"
                  value={curso}
                  type="text"
                  onChange={(e) => setCurso(e.target.value.toUpperCase())}
                />
                </p>
              </label>
            </div>
            <div className="dadosContato">
              <label htmlFor="email"> Email:
                <p>
                  <input
                    disabled={status === "Validada" || status === "Cancelada" ? true : false}
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </p>
              </label>
              <label htmlFor="telefone"> Telefone:
                <p>
                  <InputMask
                    disabled={status === "Validada" || status === "Cancelada" ? true : false}
                    mask="(99) 9 9999-9999"
                    id="telefone"
                    type="text"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </p>
              </label>
              <label htmlFor="matricula"> Matricula:
                <p>
                  <input
                    disabled={status === "Validada" || status === "Cancelada" ? true : false}
                    id="matricula"
                    type="matricula"
                    value={matricula}
                    onChange={(e) => setMatricula(e.target.value)}
                  />
                </p>
              </label>
            </div>
            <div className="dadosGestor">
              {data.role === "administrador" ?
                <label htmlFor="Select"> Gestor Responsavel:
                  <SelectGestores
                    options={newArrayGestores}
                    value={gestorId}
                    onChange={setGestorId}
                    labelledBy="Select"
                    ClearSelectedIcon={null}
                  />
                </label> : null}
              <label htmlFor="status"> Status:
                <p>
                  <select
                    id="status"
                    value={status}
                    onChange={(e) => onChangeClick(e.target.value)}
                  >
                    <option value="Recepcionada">Recepcionada</option>
                    <option value="Validada" >Validada</option>
                    <option disabled value="Renovação">Renovação</option>
                    <option disabled value="Expirada">Expirada</option>
                    <option value="Cancelada">Cancelada</option>
                  </select>
                </p>
              </label>
            </div>
            <div className="botoes">
              <button type="submit" className="botaoSalvar" onClick={(e) => editarCarteira(e)}>
                <Salvar />
                SALVAR
              </button>
              <button
                className="BtnVoltar"
                onClick={() => voltar()}>
                <Voltar />
                VOLTAR</button>
                <button
                className="BtnVisualizar"
                onClick={() => VisualizarCarteira()}>
                <Abrir />
                VISUALIZAR CARTEIRA</button>
            </div>
          </form>
        </div>
      </ContainerGlobal>
    </>
  )
}

export default EditCarteiras;