import React from "react";
import NovaPaginaVisualizarCarteiras from "./NovaPaginaVisualizarCarteiras";

const VisualizarCarteiras = () => {
  return (
    <>
    <NovaPaginaVisualizarCarteiras />
    </>
  )
}

export default VisualizarCarteiras;