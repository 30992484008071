import styled from "styled-components";
import { CloseOutline } from '@styled-icons/evaicons-outline';

export const ModalDiv = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-image: url("https://api.uniscestudantil.org.br/images/bg-padrao.jpg");
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContainer = styled.div`
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 50%;
  border-radius: 20px;

  @media (max-width: 1615px) {
    width: 55%;
    height: 55%;
  }

  @media (max-width: 1500px) {
    width: 60%;
    height: 60%;
  }

  @media (max-width: 1340px) {
    width: 70%;
    height: 70%;
  }

  @media (max-width: 1120px) {
    width: 80%;
    height: 80%;
  }

  @media (max-width: 985px) {
    width: 90%;
    height: 90%;
  }
  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
  }
`;

export const ModalButton = styled(CloseOutline)`
 cursor: pointer;
 position: relative;
 display: flex;
 align-items: center;
 right: calc(-100% + 64px);
 width: 32px;
 height: 32px;
 padding: 0;
 z-index: 0;
`;