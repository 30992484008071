import React from 'react';
import { Div, DivGlobal } from '../styled/popUpCarteira';
import copy from 'copy-to-clipboard';
import { QRCodeCanvas } from 'qrcode.react';

const PopUpDadosCarteira = ({ carteira, fecharPopUp }) => {
  console.log(typeof carteira);
  const url = `https://www.uniscestudantil.org.br/carteiras/`;

  const manterDivAberta = (e) => {
    // Impedir a propagação do clique para a DivGlobal
    e.stopPropagation();
  };
  const copyAllToClipboard = () => {
    const texto = `${carteira.name}\n${carteira.instituicaoEnsino}\n${carteira.curso}\nCPF: ${carteira.cpf}\nRG: ${carteira.rg}\nData Nasc: ${carteira.dataNascimento}`;
    copy(texto);
  }

  return (
    <DivGlobal onClick={() => fecharPopUp(carteira.id)}>
      <Div onClick={manterDivAberta}>
        <div className='container_geral'>
          <div className='img'>
            <img src={carteira.foto} alt="Imagem da carteira" />
          </div>
          <div className='dados'>
            <span> {carteira.name} </span>
            <span> {carteira.instituicaoEnsino} </span>
            <span> {carteira.curso} </span>
            <span>CPF: {carteira.cpf} </span>
            <span>RG: {carteira.rg} </span>
            <span>Data Nasc: {carteira.dataNascimento} </span>
          </div>
          <div className='infos'>
            <QRCodeCanvas className='qrCode' value={url + carteira.id} includeMargin={true} size={200}/>  
            <span className='codigoUso'> {carteira.codigoUso}</span>
          </div>
        </div>
        <button className="btn_copiar" onClick={copyAllToClipboard}> COPIAR </button>
      </Div>
    </DivGlobal>
  )
}

export default PopUpDadosCarteira;