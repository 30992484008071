import React, { useEffect, useState, useCallback } from "react";
import api, { requestData } from "../services/requests";
import { useNavigate } from 'react-router-dom';
import { Chart, KeyIcon, ModalButton, Section } from "../styled/EditarDadosUsuario";

const EditarDadosUsuario = () => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const url_atual = window.location.href;
  const id = url_atual.split('/')[4];

  const getUser = useCallback(async () => {
    const user = await requestData(`/user/${id}`);
    setEmail(user.email);
    setTelefone(user.telefone);
    setNome(user.name);
  }, [id])

  

  useEffect(() => {
    getUser()
  }, [getUser]);

  const salvarSenha = async () => {
    try {
        await api.patch(`/user/update/${id}`, {
        password: newPassword
        });
        alert('Senha atualizada com sucesso!');
        } catch (error) {
      console.log(error);
    }
  }


  const alterarSenha = () => {
    setOpenModal(true);
  }

  const backScreen = () => {
    navigate('/painel')
  }

  const fecharModal = () => {
    setOpenModal(false);
  }

  return (
    <Section>
      <div className="local">
        <div className="caminho">
          <Chart /> Painel » Perfil do Usuário
        </div>
        <button
          className="Voltar"
          onClick={() => backScreen()}
        > VOLTAR
        </button>
      </div>
      <div className="informacoes">
        <h3>Perfil do Usuário</h3>
        <div className="inputs">
          <label htmlFor="nome"> <span> Nome: </span>
            <input
              id="nome"
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </label>
          <label htmlFor="email-input"> <span> Email: </span>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor="telefone"> <span> Telefone: </span>
            <input
              id="telefone"
              type="text"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            />
          </label>
        </div>
        <button className="btnAlterarPass" onClick={() => alterarSenha()}> 
        <KeyIcon />
        ALTERAR SENHA
        </button>
      </div>
      {openModal &&
        <>
          <div className="modal">
            <div className="alterarPass">
              <ModalButton onClick={() => fecharModal()} />
              <h3> ALTERAR SENHA </h3>
              <label htmlFor="Password">
                <span>
                  Nova Senha: </span>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              <label htmlFor="newPassword" > <span> Confirmar a Nova Senha:</span>
                <input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </label>
              <button 
              disabled={newPassword === password & password !== "" ? false : true}
              className="salvarPass"
              onClick={() => salvarSenha()}>
              SALVAR </button>
            </div>
          </div>
        </>
      }
    </Section>
  )
}

export default EditarDadosUsuario;