import React from "react";

const EditarImagem = ({ img, setImagem, imagemInicial }) => {
  return (
    <>
        <input className="inputEditarImagem" type="file" name="imagem" onChange={(e) => setImagem(e.target.files[0])} />
        {img ? <img className="editarImagem" src={URL.createObjectURL(img)} alt="imagem" /> : <img className="editarImagem" src={imagemInicial} alt="imagem" />}
    </>
  )
}

export default EditarImagem;