import styled from "styled-components";

export const Global = styled.div`
background-color: #ccddf8;
min-height: 100vh;

.deleter-carteiras {
  .titulo-deletar {
    display: flex;
    background-color: #77818e;
    align-items: center;

  }
    .card-opcoes {
      display: flex;
      align-items: center;
      min-height: 50px;
      justify-content: space-between;
      margin: 15px;
      background-color: #dae4f2;

      .card-deletar {
        label {
          padding: 10px;
        }
      }
      .buttons {
        .excluir {
          margin: 15px;
          border: none;
          border-radius: 5px;
          background-color: #df1212;
          color: white;
          box-shadow:
                -1px -1px 5px 0px #fff,
                7px 7px 20px 0px #0003,
                4px 4px 5px 0px #0002;
          }
        }
      }
  }

.gestores {
  flex-direction: column;

  .gestores-titulo {
    display: flex;
    background-color:  #77818e;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

  .bttn-cadastrar-gestores {
      border: none;
      border-radius: 5px;
      max-width: 130px;
      height: 68px;
      line-height: 25px;
      transition: all .3s;
      display: inline-block;
      color: #fff;
      font-size: 1rem;
      letter-spacing: .15rem;
      transition: all .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
        &:after {
          content: '';          
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #0d5ec7;
          z-index: -2;
        }
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: #13b7a194;
          transition: all .3s;
          z-index: -1;
         }
        &:hover {
          color: #fff;
          &:before {
          width: 100%;
        }
     }
  }
  }

  .deletar-gestores {
    .card-global {
      .card-titulo {
        text-align: center;
      }
      .card-gestores {
          margin: 15px;
          display: flex;
          justify-content: space-between;
          background-color: #dae4f2;
          align-items: center;
          .card-buttons {
            margin: 15px;
            .bloquear {
              border: none;
              border-radius: 5px;
              background-color: #3a36c0;;
              color: white;
              margin-right: 20px;
              box-shadow:
                -1px -1px 5px 0px #fff,
                7px 7px 20px 0px #0003,
                4px 4px 5px 0px #0002;
            }

            .desbloquear {
              border: none;
              border-radius: 5px;
              background-color: #3a36c0;;
              color: white;
              margin-right: 20px;
              box-shadow:
                -1px -1px 5px 0px #fff,
                7px 7px 20px 0px #0003,
                4px 4px 5px 0px #0002;
            }

            .excluir {
              border: none;
              border-radius: 5px;
              background-color: #df1212;
              color: white;
              box-shadow:
                -1px -1px 5px 0px #fff,
                7px 7px 20px 0px #0003,
                4px 4px 5px 0px #0002;
          }
        }
      }
    } 
  }
}`;