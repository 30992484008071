import styled from "styled-components";


export const DivGlobal = styled.div`
`;


export const Header = styled.header`
display: flex;
justify-content: space-around;
align-items: center;
height: 6vw;
background-color: #3B71DA;

    .container_img {
        display: flex;
        align-items: center;
        height: 100%;
            img {
                max-height: 80%;
            }
    }

    .menu_pc {
        display: flex;
        align-items: center;
        justify-content: space-around;

        a {
            text-decoration: none;
            font-size: 1vw;
            color: #fff;
            font-family: 'Neo Sans Pro', sans-serif;
            font-weight: bold;
            margin: 0 20px 0 20px;
        }

        .download {
            cursor: pointer;
            margin: 0 20px 0 20px;
            font-family: 'Neo Sans Pro', sans-serif;
            font-size: 1vw;
            font-weight: bold;
            background-color: #FAB035;
            border: none;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            color: #fff;
            height: 4vh;
            width: 10vw;
            
            @media screen and (max-width: 900px) {
            height: 30px;
            width: 8vw;
            }
        }

        .btn_renovar {
            cursor: pointer;
            margin: 0 20px 0 20px;
            font-family: 'Neo Sans Pro', sans-serif;
            font-size: 1vw;
            font-weight: bold;
            background-color: #fa3535;
            border: none;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            color: #fff;
            height: 4vh;
            width: 10vw;
            
            @media screen and (max-width: 900px) {
            height: 30px;
            width: 8vw;
            }
        }
    } 
`;

export const Section = styled.section`
display: flex;
flex-direction: column;
background-color: #FBF5EF;
width: 100%;

    .tittle {
        h1 {
            text-align: center;
            font-family: 'Neo Sans Pro', sans-serif;
            font-weight: bold;
            font-size: 3vw;
            margin: 80px 0 50px 0;
        }
    }

    .container_cards {
        display: flex;
        justify-content: center;
        margin-bottom: 100px;

        .card1 {
            width: 70%;
            display: flex;
            align-items: center;
            position: relative;
            justify-content: center;

            .student_img {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 50%;

                img {
                    width: 20vw;
                }
            }

            .student_data {
                margin-left: 50px;
                max-width: 50%;
                display: flex;
                flex-direction: column;

                .student_documents  {
                    font-family: 'Neo Sans Pro', sans-serif;
                    font-weight: 500;
                    margin-bottom: 2px;
                    font-size: 1.5vw; 
                }

            > div:nth-child(-n+3) {
                margin-bottom: 2px;
                display: flex;
                flex-direction: column;

                    .title {
                        font-family: 'Neo Sans Pro', sans-serif;
                        margin-bottom: 0;
                        font-size: 1.5vw;                    
                    }

                    span {
                        margin: 1px 0 1px 0;
                        font-family: 'Neo Sans Pro', sans-serif;
                        font-weight: 500;
                        font-size: 2vw;               
                    }
                }
            
                > div:nth-last-child(-n+3) { 
                    margin-bottom: 2px;

                    .title {
                        font-family: 'Neo Sans Pro', sans-serif;
                        margin-bottom: 0;
                        font-size: 1.5vw;                    
                    }

                    span {
                        margin: 1px 0 1px 0;
                        font-family: 'Neo Sans Pro', sans-serif;
                        font-weight: 500;
                        font-size: 2vw;               
                    }
                }
            }
        }

        .card1::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: -60px;
                width: 3px; 
                transform: scaleY(0.7);
                background-color: #000000;
        }

        .card2 {
            display: flex;
            width: 30%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 60px;

            .infos {
                margin-bottom: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                div {
                    font-family: 'Neo Sans Pro', sans-serif;
                    font-weight: 500;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column;

                    span {
                        font-family: 'Neo Sans Pro', sans-serif;
                        font-weight: 500;
                        font-size: 2vw;
                        text-align: center;
                        margin-bottom: 2px;
                    }
                }
            }
        
            .status_valida {
                align-self: center;
                border: none;
                background-color: #57e5a077;
                font-family: 'Neo Sans Pro', sans-serif;
                font-weight: 500;
                height: 8vh;
                width: 70%;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                color: #138A51;
                font-size: 2vw;
            }

            .status_expirada {
                align-self: center;
                border: none;
                background-color: #ba00006d;
                font-family: 'Neo Sans Pro', sans-serif;
                font-weight: 500;
                height: 8vh;
                width: 70%;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                color: #ba0000;
                font-size: 2vw;
            
            }

            .status_recepcionada {
                align-self: center;
                border: none;
                background-color: #3b70da90;
                font-family: 'Neo Sans Pro', sans-serif;
                font-weight: 500;
                height: 8vh;
                width: 70%;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                color: #3b70da;
                font-size: 2vw;
            }
        }
    }
`;


export const Footer = styled.footer`
background-color: #DDE0E4;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

.social_name {
    margin-top: 15px;
    h3 {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 1.5vw;
        margin-bottom: 5px;
    }

}

.complete_name {
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 22%;

    span {
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 1.2vw;
        margin-bottom: 5px;
    }

}


.contact {
    margin-bottom: 20px;
    span {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 1vw;
    }

}
`;