import styled from "styled-components";
import {ChartColumn} from "@styled-icons/fa-solid";
import { CloseOutline } from '@styled-icons/evaicons-outline';
import { Key } from '@styled-icons/boxicons-regular';


export const KeyIcon = styled(Key)`
max-width: 20px;
`;

export const Chart = styled(ChartColumn)`
max-width: 20px;
margin-right: 5px;
`;

export const ModalButton = styled(CloseOutline)`
width: 20px;
height: 50px;
`;

export const Section = styled.section`
min-height: 100vh;
background-color: rgb(244, 244, 242);

.local {
height: 50px;
display: flex;
align-items: center;
font-size: 14px;
margin-left: 20px;
justify-content: space-between;

.caminho {
display: flex;
width: 250px;
align-items: center;
font-size: 14px;
margin-left: 10px;
}

button {
  margin-right: 20px;
  background-color: #24b417af;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

}

.informacoes {
  background-color: #fff;
  height: 400px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3 {
    margin-left: 10px;
    color: #3465b2;
    font-size: 24px;
    font-weight: 300;
  }

  .inputs {
  display: grid;
  grid-template-columns: 400px 400px;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

 
  label {
    margin: 10px;
    height: 40px;

    span {
    margin-bottom: 10px;
  }

    input {
      margin-top: 5px;
      height: 100%;
      width: 100%;
    }
  }

  }

  .btnAlterarPass {
    display: flex;
    position: relative;
    color: white;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    width: 170px;
    top: 100px;
    left: 11px;
    background-color: #f0ad4e;
    border: none;
    padding: 6px 12px;
  }
}

.modal {
border: 1px solid;
background-color: gray;
opacity: 0.92;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
min-width: 100vw;
min-height: 100vh;
left: 0px;
top: 0px;

.alterarPass {
background-color: #fff;
opacity: 1;
display: flex;
flex-direction: column;
width: 500px;
height: 400px;
justify-content: center;

label {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  height: 50px;
  margin: 30px 0px 40px 50px;

  input {
    height: 50px;
    }
  }
}

h3 {
font-size: 21px;
align-self: flex-start;
margin-left: 50px;
color: #428bca;
font-weight: 500;
}

.salvarPass {
align-self: center;
width: 70px;
height: 40px;
:enabled {
background-color: green;
color: white;
border: none;
width: 70px;
height: 40px;
}
}

${ModalButton} {
  color: red;
  position: relative;
  top: 10px;
  left: 90%;
  }
}

`;