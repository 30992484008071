import React, { useState, useMemo } from "react";
import moment from 'moment';
import { Page } from "../styled/Pagination";
import api, { criarCarteiras } from '../services/requests';
import PopUpDadosCarteira from "./popUpDadosCarteira";
import copy from 'copy-to-clipboard';
import {
  CarteiraContainer,
  DivCarteiras,
  DivRenovacoes,
  ContainerRenovacoes,
  Container2,
  Tbody,
  Table,
  Thead,
  Editar,
  Abrir,
  Resultados,
  Copiar,
  Renovar
} from '../styled/Carteiras';

const TabelaCarteirasFiltro = ({ filtros, toggleListVisibility }) => {
  const date = new Date();
  const newDate = moment(date).locale('pt-br').format('YYYY-MM-DD HH:mm:ss');
  const dataValidade = new Date(date.getFullYear() + 1, 2, 31);
  let PageSize = 10;
  const [page, setCurrentPage] = useState(1);
  const [observacao, setObservacao] = useState("");
  const [openPopUps, setOpenPopUps] = useState({});

  const carteiras = filtros.sort((a, b) => a.dataEmissao > b.dataEmissao ? -1 : a.dataEmissao < b.dataEmissao ? 1 : 0);

  function periodoDeRenovacao(carteira) {
    const dataHoje = newDate.split(' ')[0];
    const anoDataValidade = carteira.dataValidade.split('-')[0];
    const periodoRenovacao = new Date(anoDataValidade, 0, 1).toISOString().split('T')[0];
    const validadeCarteira = carteira.dataValidade.split('T')[0];
    return dataHoje >= periodoRenovacao && dataHoje <= validadeCarteira;
  }

  const handleClick = async (carteira) => {
    try {
      const endpoint = '/carteiras/renovacao';
      await criarCarteiras(endpoint, {
        foto: carteira.foto,
        codigoUso: carteira.codigoUso,
        nome: carteira.name,
        instituicaoEnsino: carteira.instituicaoEnsino,
        curso: carteira.curso,
        cpf: carteira.cpf,
        rg: carteira.rg,
        dataNascimento: carteira.dataNascimento,
        dataValidade,
        dataEmissao: newDate,
        user_id: carteira.user_id,
        email: carteira.email,
        telefone: carteira.telefone,
      });
      window.location.reload();
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  }

  const abrirPopUp = (id) => {
    setOpenPopUps(prevState => ({
      ...prevState,
      [id]: true // Define o pop-up com o ID correspondente para ser exibido
    }));
    document.body.classList.add('no-scroll');
  };

  const fecharPopUp = (id) => {
    setOpenPopUps(prevState => ({
      ...prevState,
      [id]: false // Define o pop-up com o ID correspondente para ser fechado
    }));
    document.body.classList.remove('no-scroll');
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return carteiras.slice(firstPageIndex, lastPageIndex);
  }, [page, PageSize, carteiras]);

  const onChangeClick = async (id, observacao) => {
    setObservacao(observacao);
    try {
      await api.patch(`/carteiras/observacao/${id}`, { observacao });
    } catch (error) {
      console.log(error);
    }
  };

  const copyToClipboard = (text) => {
    const url = `${window.location.origin}/carteiras/${text}`;
    copy(url);
  }

  return (
    <>
      <Table contenteditable="true">
        <Thead>
          <tr>
            <th>Foto</th>
            <th>Codigo Uso</th>
            <th>Nome
              <p>Inst.Ensino</p>
              <p>Curso</p>
            </th>
            <th>CPF
              <p>RG</p>
            </th>
            <th>Data Nascimento </th>
            <th>Data Emissão
              <p>Data Validade </p>
            </th>
            <th>Status</th>
            <th>Ações Disponiveis</th>
          </tr>
        </Thead>
        <Tbody>
          {currentTableData.length <= 0 ? <tr>
            <th>
              <h2>Carregando...</h2>
            </th>
          </tr> : currentTableData
            .map((carteira) => (
              <CarteiraContainer id={carteira.id} key={carteira.id} onClick={(e) => toggleListVisibility(e)}>
                <DivCarteiras id={carteira.id}>
                  <td> <img className="img_aluno" src={carteira.foto} alt="foto_Aluno" /> </td>
                  <td> {carteira.codigoUso} </td>
                  <td>
                    <p>{carteira.name}
                      <span>{carteira.name}</span>
                    </p>
                    <p>{carteira.instituicaoEnsino}
                      <span>{carteira.instituicaoEnsino}</span>
                    </p>
                    <p>{carteira.curso}
                      <span>{carteira.curso}</span>
                    </p>
                  </td>
                  <td> {carteira.cpf}
                    <p> {carteira.rg} </p>
                  </td>
                  <td> {carteira.dataNascimento} </td>
                  <td className="dataEmissao"> {moment(carteira.dataEmissao).zone('-0000').format('DD/MM/YYYY HH:mm:ss')}
                    <p className="dataValidade">
                      {moment(carteira.dataValidade).locale('pt-br').format('DD/MM/YYYY')}
                    </p>
                  </td>
                  <td> {carteira.status}  </td>
                  <td>
                    <a className="botaoVisualizar"
                      href={`/carteiras/${carteira.id}`} target="_blank" rel="noreferrer">
                      <Abrir />
                      Visualizar
                    </a>
                    {carteira.status !== 'Expirada' ?
                      <a
                        className="botaoEditar"
                        href={`/carteiras/editar/${carteira.id}`}>
                        <Editar />
                        Editar</a> : null
                    }
                    {
                      (carteira.status === "Validada" || carteira.status === "Recepcionada") &&
                      <>
                        {carteira.status === "Validada" && (
                          <button className="copy" onClick={() => copyToClipboard(carteira.id)}>
                            <Copiar />
                            Copiar Url
                          </button>
                        )}
                        <button className="popUp" onClick={() => abrirPopUp(carteira.id)}>
                          Dados
                        </button>
                        {openPopUps[carteira.id] && <PopUpDadosCarteira carteira={carteira} fecharPopUp={fecharPopUp} />}
                      </>
                    }
                    {periodoDeRenovacao(carteira) === true || carteira.status === 'Expirada' ?
                      <button className="renovacao" onClick={() => handleClick(carteira)}>
                        <Renovar />
                        Renovação </button> : null}
                  </td>
                </DivCarteiras>
                <td className="renovacao" id="tabela" style={{ display: 'none' }}>
                  <div>
                    <h4>Renovações</h4>
                    <ContainerRenovacoes>{carteira.renovacoes.map((c) => (
                      <Container2 key={c.id}>
                        <DivRenovacoes> {c.codigoUso} </DivRenovacoes>
                        <DivRenovacoes> {c.status} </DivRenovacoes>
                        <DivRenovacoes> {c.instituicaoEnsino} </DivRenovacoes>
                        <DivRenovacoes> {c.curso} </DivRenovacoes>
                        <DivRenovacoes> {moment(c.dataValidade).locale('pt-br').format('DD/MM/YYYY')} </DivRenovacoes>
                        <DivRenovacoes> {moment(c.dataEmissao).locale('pt-br').format('DD/MM/YYYY h:mm:ss a')} </DivRenovacoes>
                        <td>
                        </td>
                      </Container2>
                    ))}
                    </ContainerRenovacoes>
                  </div>
                  <label> observações
                    <textarea
                      value={observacao}
                      placeholder={carteira.observacao}
                      onChange={(e) => onChangeClick(carteira.id, e.target.value)} />
                  </label>
                </td>
              </CarteiraContainer>
            ))}
        </Tbody>
      </Table>
      <Resultados className="Resultados"> {filtros.length} Resultados encontrados </Resultados>
      <Page
        className="pagination-bar"
        currentPage={page}
        totalCount={filtros.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
    </>
  )
};

export default TabelaCarteirasFiltro;