import styled from "styled-components";

export const Background = styled.section`
display: flex;
height: 100vh;
justify-content: space-around;
align-items: center;
background-image: url("https://api.uniscestudantil.org.br/images/bg-padrao.jpg");
background-repeat: no-repeat;
background-size: cover;

.containerCentral {
  background-color: #d2cfcf;
  display: flex;
  justify-content: space-between;
  width: 70%;
  height: 50%;

.divImg {
  width: 50%;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  img {
    max-width: 300px;
    max-height: 100px;
  }
}

}

form {
  align-self: center;
  background-color: #fffbfb;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 50%;

  .areaLogin {
    display: flex;
    margin: 10px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start; 

    button {
      margin-top: 10px;
      align-self: flex-end;
    }

    input { 
      width: 100%;
      height: 100%;
    }

    label {
      padding: 20px 0 20px 0;
      width: 20vw;
      height: 5vh;
    }
  }
}
`;
