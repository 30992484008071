import styled from 'styled-components';
import { PersonDeleteOutline } from '@styled-icons/evaicons-outline';
import { Save } from '@styled-icons/boxicons-regular';
import { ArrowBack } from '@styled-icons/typicons';

export const Voltar = styled(ArrowBack)`
max-width: 20px;
color: white;
`;

export const Delete = styled(PersonDeleteOutline)`
max-width: 20px;
color: white;
`;

export const Salvar = styled(Save)`
max-width: 20px;
color: white;
`;

export const ContainerGlobal = styled.div`
display: flex;
align-items: center;
min-height: 100vh;
justify-content: center;
background-color: #d1cfcf;
background-image: url("https://api.uniscestudantil.org.br/images/bg-padrao.jpg");

.bodyFake {
min-width: 60%;
display: flex;
align-items: flex-start;
background-color: #fff;

.inputEditarImagem {
  z-index: 1;
  position: relative;
  opacity: 0;
  top: 100px;
  left: 40px;
  width: 150px;
  height: 150px;
}

.editarImagem {
  border: 1px solid;
  z-index: 0;
  left: -100px;
  top: 100px;
  position: relative;
  max-width: 150px;
  max-height: 150px;
}

.formEditarImagem {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  label {
    margin: 25px;
  }

  .dadosAluno {
    display: flex;
    max-width: 1000px;
    flex-wrap: wrap;
  }

  .DadosInstituicao {
    align-self: flex-start;
    display: flex;
  }

  .dadosContato {
    align-self: flex-start;
    display: flex;
  }

  .matricula {
    display: flex;
    align-self: flex-start;
  }
  
  .dadosGestor {
    margin-top: -246px;
    width: 468px;
    display: flex;
    align-self: flex-end;
    justify-content: space-around;
   

    @media (max-width: 1120px) {
      display: flex;
      margin-top: 0px;
      margin-left: -550px;      
      width: auto;
    }
  }

  .botoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 160px 0px 50px 0px;
  width: 350px;

  .botaoSalvar {
  display: flex;
  width: 100px;
  height: 30px;
  background-color: green;
  align-items: center;
  font-family: cursive;
  justify-content: space-around;
  border: none;
  color: white;
}

.BtnVoltar {
  display: flex;
  width: 80px;
  height: 30px;
  color: white;
  justify-content: space-around;
  align-items: center;
  color: #ffff;
  font-family: cursive;
  background-color: #ce7c36;
  padding: 5px;
  border: none;
    }

  .BtnVisualizar {
  display: flex;
  width: 110px;
  height: 30px;
  color: white;
  justify-content: space-around;
  align-items: center;
  color: #ffff;
  font-family: cursive;
  background-color: #3889f4;
  padding: 5px;
  border: none;
}
  }



}

}`;
