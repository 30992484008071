import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Gavetas from './gavetas';
import { DivGlobal, FacebookIcon, Header, InstagramIcon, LinkedinIcon, TwitterIcon, MenuIcon } from '../styled/PaginaPrincipal';
import api from '../services/requests';

const PaginaPrincipal = () => {

  const [openMenu, setOpenMenu] = useState(false);
  const [resize, setResize] = useState(false);
  const [pesquisar, setPesquisar] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const mediaQuery = window.matchMedia('(max-width: 700px)');

  const abrirMenu = () => {
    setOpenMenu(!openMenu)
  }

  useEffect(() => {
    if (mediaQuery.matches !== resize) {
      setResize(mediaQuery.matches);
    }
    const listener = () => {
      setResize(mediaQuery.matches);
    };
    mediaQuery.addEventListener('change', listener);
    return () => mediaQuery.removeEventListener('change', listener);

  }, [mediaQuery, resize]);

  const handleChange = async (e) => {
    setPesquisar(e.target.value.replace(/\D/g, ''))
  
    setError(false);
  }

  const handleClick = async () => {
    try {
      const { data } = await api.get(`/carteiras/pesquisar?elemento=${pesquisar}`);
      navigate(`/carteiras/${data.id}`);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  }

  return (
    <>
      <Header> SIGA NOSSAS REDES SOCIAIS
        <a href='https://www.facebook.com/uniscestudantil' target="_blank" rel="noreferrer"> <FacebookIcon /> </a>
        <a href='https://www.twitter.com/uniscestudantil' target="_blank" rel="noreferrer"> <TwitterIcon /> </a>
        <a href='https://www.instagram.com/uniscestudantil' target="_blank" rel="noreferrer"> <InstagramIcon /> </a>
        <a href='https://www.linkedin.com/company/uniscestudantil' target="_blank" rel="noreferrer"> <LinkedinIcon /> </a>
      </Header>
      <DivGlobal>
        <div className='AtalhosDoSite'>
          <div className='Logo'>
            <img src="https://api.uniscestudantil.org.br/images/000065-01-l.png" alt="logo" />
          </div>
          {(resize) ?
            <>
              <MenuIcon
                className='openMenu'
                onClick={() => abrirMenu()}> Menu </MenuIcon>
              {openMenu &&
                <div className='OpcoesMenu'>
                  <div className='Menu'>
                    <a href="/painel">PAINEL</a>
                  </div>
                </div>}
            </> :
            <div className='Opcoes'>
              <a href="/painel">PAINEL</a>
            </div>}
        </div>
        <div className='container_logo'>
          <div className='container_info'>
            <div className='texto'>
              <h1>PORTAL <br></br>
                UNISC ESTUDANTIL</h1>
              <h2>
                Só na UNISC
                Estudantil <br></br> você
                tem atendimento personalizado
                direto pelo WhatsApp </h2>
              <button
                className='consulta'
                onClick={() => document.querySelector('#verify').scrollIntoView({behavior: 'smooth'})}  
              >
                CONSULTAR CARTEIRA
              </button>
            </div>
            <div className='img'>
              <img src='https://api.uniscestudantil.org.br/images/aluna_unisc.png' alt='aluna' />
            </div>
          </div>
        </div>
        <section className='carteiras_estudantis'>
          <div className='container_carteiras'>
            <div className='container_img'>
              <img src='https://api.uniscestudantil.org.br/images/carteiras_segunda_pagina.png' alt='carteira' />
            </div>
            <div className='container_texto'>
              <h3>CARTEIRAS <br></br>
                FÍSICA E DIGITAL</h3>
              <ul>
                <li>Validade 31/03/2025. </li>
                <li>Envio para todo o Brasil (correios). </li>
                <li>Suporte via WhatsApp.</li>
                <li>Válida em todo território nacional.</li>
                <li>Eventos culturais e esportivos.</li>
              </ul>
              <h3> R$:50,00 + FRETE </h3>
            </div>
          </div>
          <div className='container_texto2'>
            <h3>Atenção</h3>
            <span> A foto para carteira deve seguir o padrão
              para<br></br>  documento assim  como nas
              carteiras de<br></br>  exemplo acima. </span>
          </div>
        </section>
        <section id='verify' className='container_verify'>
          <div className='container_title'>
            <h3>VERIFIQUE SUA CARTEIRA</h3>
          </div>
          <div className='container_middle'>
            <div className='div_text'>
              <p> Preencha o campo abaixo <br></br>
                com <span>CPF</span> ou <span>Código de uso </span>
                contidos na carteira </p>
              <input 
              placeholder='CPF / COD. DE USO'
              value={pesquisar}
              onChange={(e) => handleChange(e)}
              />
              { (error) && <span className='error_message'> CARTEIRA NÃO ENCONTRADA! </span> }
              <button
              className='btn_consultar'
              onClick={() => handleClick()}>
                CONSULTAR
                </button>
            </div>
            <div className='div_img'>
              <img src='https://api.uniscestudantil.org.br/images/carteira_2024_principal.png' alt='carteira_principal' />
            </div>
          </div>
          <div className='container_footer'>
            {(resize) ?
              <span>
                Nossa carteira deve ser consultada
                somente através do nosso site
              </span> :
              <span>
                Nossa carteira deve ser consultada <br></br>
                somente através do nosso site
              </span>
            }
          </div>
        </section>
        <section className='Faqs'>
          <div className='container_img'>         
              <img src="https://api.uniscestudantil.org.br/images/icone_faq.png" alt='faq' />
          </div>
          <Gavetas />
        </section>
        <footer className='footer'>
          <div className='contato'>
            <div className='email_texto'>
              <p>uniscestudantil@gmail.com</p>
            </div>

          </div>
          <div className='redes'>
            <a className="in" href='https://www.linkedin.com/company/uniscestudantil' target="_blank" rel="noreferrer">
              <img src="./IN.svg" alt="linkedin" />
            </a>
            <a className="face" href='https://www.facebook.com/uniscestudantil' target="_blank" rel="noreferrer">
              <img src="./fb.svg" alt="facebook" />
            </a>
            <a className="insta" href='https://www.instagram.com/uniscestudantil' target="_blank" rel="noreferrer">
              <img src="./insta.svg" alt="instagram" />
            </a>
            <a className="twitter" href='https://www.twitter.com/uniscestudantil' target="_blank" rel="noreferrer">
              <img src="./twitter.svg" alt="twitter" />
            </a>
          </div>
          <div className='infos'>
            <h3>UNISC ESTUDANTIL</h3>
            <p>UNIÃO ESTUDANTIL NACIONAL DE INCENTIVO SOCIAL E CULTURAL </p>
            <p>CNPJ: 31.370.444/0001-42 </p>
          </div>
        </footer>
      </DivGlobal>
    </>
  )
}

export default PaginaPrincipal;