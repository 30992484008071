import React from "react";
import { ModalContainer, ModalDiv, ModalButton } from "../styled/Modal";
import CriarCarteira from "./criarCarteira";

const Modal = ({ modalVisivel, setModalVisivel }) => {

  const handleClick = (value) => {
    setModalVisivel(value);   
    window.location.reload()
  }

  return (
    <>
      {
        modalVisivel ?
          <ModalDiv className="ModalDiv">
            <ModalContainer>
              <ModalButton onClick={() => handleClick(false)} />
              <CriarCarteira />
            </ModalContainer>
          </ModalDiv>
          : null}
    </>
  )
}

export default Modal;