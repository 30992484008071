import { Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';
import Login from './paginas/login';
import Painel from './paginas/painel';
import UserPainel from './paginas/user_painel';
import Carteiras from './paginas/carteiras';
import EditarCarteiras from './paginas/editarCarteiras';
import Principal from './paginas/principal';
import Gestores from './paginas/Gestores';

function App() {

  const user = JSON.parse(localStorage.getItem('user'));

  const ProtectedRoute = ({
    user,
    redirectPath = '/login',
    children,
  }) => {
    if (!user) {
      return <Navigate to={redirectPath}/>;
    }
    return children;
  };

  return (
    <Routes>
      <Route path="/" element={<Principal />} />
      <Route path="/login" element={<Login />} />
      <Route path="/painel" element={
      <ProtectedRoute user={user}>
        <Painel />
      </ProtectedRoute>} />
      <Route path="/user/:id" element={
      <ProtectedRoute user={user}>
        <UserPainel />
      </ProtectedRoute>} />
      <Route path="/carteiras/:id" element={<Carteiras />} />
      <Route path="/carteiras/editar/:id" element={
      <ProtectedRoute user={user}>
        <EditarCarteiras />
      </ProtectedRoute>} />
      <Route path="/gestores" element={
      <ProtectedRoute user={user}>
        <Gestores />
      </ProtectedRoute>} />
    </Routes>
  );
}

export default App;
