import styled from "styled-components";


export const DivGlobal = styled.div`
margin: 0;
padding: 0;
border-radius: 10px;
width: 390px;
height: 844px;
background: linear-gradient(90deg, rgb(66, 211, 255) 0%, rgba(10, 186, 239, 0.937) 100%);

.logos {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;  
    display: flex;
    justify-content: space-around;
 

    .dne {
        width: 115.594px;
        height: 108px;
    }

    .logos_unisc {
        display: flex;
        flex-direction: column;
   
        .logo_unisc {
            width: 144.5px;
            height: auto;
            margin-bottom: 10px;
        }

        .logo_2024 {
            width: 144.5px;
            height: auto;
        }
    }
}

.foto_qr {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;

    .foto_aluno {
        width: 150px;
        height: auto;
        border-radius: 10px;
    }


    .qr_cod {
        .editQr {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffff;
            border-radius: 10px;

            .qrCode {
                border-radius: 10px;
            }
        }

        .codUso {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            text-align: center;
            font-family: 'Calibri', sans-serif;
            font-weight: bold;
            .codigoUso {
                font-size: 20px;
            }
        }
    }
}

.dadosEstudante {
    border-radius: 20px;
    margin-left: 20px;
    margin-top: 30px;
    background-color: rgba(252, 250, 251, 0.63);
    width: 350px;
    height: 350px;
    position: relative;
    padding-top: 1px;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(10, 186, 239, 0.13); /* Cor #0abaef com 13% de opacidade */
    }


    div:nth-child(-n+3) {
        display: flex;
        flex-direction: column;
        font-family: 'Calibri', sans-serif;
        font-weight: bold;
        margin: 20px 20px 15px 20px;

        .title {            
                 margin-bottom: 0;
                 font-size: 16px;
            }

            span {
                margin: 1px 0 1px 0;
                font-size: 18px;
            }
    }

  
    .student_documents  {
        font-family: 'Calibri', sans-serif;
        font-weight: bold;
        margin-bottom: 2px;
        margin-left: 20px;
        margin-top: 30px;
        font-size: 16px;
    }

    .student_cpf {
        font-family: 'Calibri', sans-serif;
        font-weight: bold;
        margin: 5px 20px 0px 20px;

        .title {
        margin-bottom: 0;
        font-size: 16px;
        }

        span {
        margin: 1px 0 1px 0; 
        font-size: 18px;          
        }
    }

    div:nth-last-child(-n+3) {
        font-family: 'Calibri', sans-serif;
        font-weight: bold;
        margin: 5px 20px 0px 20px;

        .title {
        margin-bottom: 0;
        font-size: 16px;
        }

        span {
        margin: 1px 0 1px 0; 
        font-size: 18px;          
        }
    }
}

.icon_verify {
    display: flex;
    justify-content: center;
    margin-top: 5px;

}

`