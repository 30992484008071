import styled from "styled-components";

export const DivGlobal = styled.div`
backdrop-filter: blur(4px); /* Valor do desfoque em pixels */
background-color: rgba(255, 255, 255, 0.308); /* Cor de fundo com transparência */
z-index: 0;
top: 0;
left: 0;
height: 100vh;
width: 100vw;
position: fixed;
overflow: hidden;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  background-color: #AAC6FC;
  position: fixed;
  border-radius: 10px;
  padding: 20px;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 35%;
  transform: translate(-50%, -50%); 

  .container_geral {
    display: flex;
    align-items: center;
    width: 85%;
    height: 100%;
  .img {
    width: 20%;
    height: 90%;
    margin-right: 20px;
    margin-left: 50px;

    img {
        width: 90%;
        height: 100%;
        border-radius: 10px;
    }
  }   
  

  .dados {
    margin-right: 100px;
    width: 35%;
    display: flex;
    flex-direction: column;
    font-family: 'Neo Sans Pro', sans-serif;
    font-weight: 700;

    span {
        font-size: 1.2vw;
        margin-bottom: 3px;
    }
  }

  .infos {
    font-family: 'Neo Sans Pro', sans-serif;
    font-weight: 700;
    align-self: center;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    height: 90%;

    .qrCode {
        width: 70%;
        height: 60%;
        border-radius: 10px;
    }

    .codigoUso {
        font-size: 1.2vw;
        margin-top: 30px;
    }
  }
}

.btn_copiar {
        font-size: 20px;
        top: 40%;
        right: 40%;
        margin-top: 20px;
        width: 15%;
        height: 20%;
        border-radius: 10px;
        background-color: #FAB035;
        font-family: 'Neo Sans Pro', sans-serif;
        border: none;
        cursor: pointer;
    }

`;