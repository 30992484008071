import React, { useEffect, useState, useRef } from "react";
import { DivGlobal } from "../styled/DownloadCarteira";
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { QRCodeCanvas } from "qrcode.react";

const DownloadCarteiras = ({ carteira, setComponenteRenderizado }) => {
    const ref = useRef(null);
    const url_atual = window.location.href;

    const picture = () => {
        const node = document.getElementById('download_carteira');

        html2canvas(node, {
          allowTaint: true,
          useCORS: true,
      }).then((canvas) => {
          const base64URl = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
          saveAs(base64URl, `${carteira.codigoUso}.jpeg`);
        });
      }   

    useEffect(() => {
        picture();
        setComponenteRenderizado(false);
    }, []);

    return (
        <DivGlobal ref={ref} id="download_carteira">
            <div className="logos">
                <img className="dne" src="https://api.uniscestudantil.org.br/images/DNE.png" alt="dne" />
                <div className="logos_unisc">
                    <img className="logo_unisc" src="https://api.uniscestudantil.org.br/images/logo_unisc.png" alt="logo_unisc" />
                    <img className="logo_2024" src="https://api.uniscestudantil.org.br/images/2024.png" alt="logo_2024" />
                </div>
            </div>
            <div className="foto_qr">
                <img src={carteira.foto} className="foto_aluno" alt="foto_aluno" />
                <div className="qr_cod">
                    <div className="editQr">
                    <QRCodeCanvas className='qrCode' value={url_atual} includeMargin={true} size={135}/>     
                    </div>
                    <div className="codUso">
                        <span> COD. DE USO</span>
                        <span className="codigoUso"> {carteira.codigoUso}</span>
                    </div>
                </div>
            </div>
            <div className="dadosEstudante">
                <div className="student_name">
                    <span className="title">Nome:</span>
                    <span>{carteira.name}</span>
                </div>
                <div className="student_class">
                    <span className="title">Instituição de ensino:</span>
                    <span>{carteira.instituicaoEnsino}</span>
                </div>
                <div className="student_course">
                    <span className="title">Curso:</span>
                    <span>{carteira.curso}</span>
                </div>
                <p className="student_documents">Documentos:</p>
                <div className="student_cpf">
                    <span className="title">CPF:</span>
                    <span>{carteira.cpf}</span>
                </div>
                <div className="student_rg">
                    <span className="title">RG:</span>
                    <span>{carteira.rg}</span>
                </div>
                <div className="student_birth">
                    <span className="title">Data Nasc: </span>
                    <span>{carteira.dataNascimento}</span>
                </div>
            </div>
            <div className="icon_verify">
                <img className="verificado" src="https://api.uniscestudantil.org.br/images/verificado.png" alt="verificado" />
            </div>
        </DivGlobal>
    )
}

export default DownloadCarteiras;