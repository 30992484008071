import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestLogin } from '../services/requests';
import { cpfMask } from '../services/funcoesAuxiliares';
import { Background } from '../styled/Login';

const Login = () => {
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [isLogged, setIsLogged] = useState({ role: '', logged: false });
  const [failedTryLogin, setFailedTryLogin] = useState(false);
  const navigate = useNavigate();

  const login = async (event) => {
    event.preventDefault();
    try {
      const endpoint = '/login';
      const user = await requestLogin(endpoint, { cpf, password });
      localStorage.setItem('user', JSON.stringify(user.token));
      setIsLogged({ role: user.role, logged: true });      
    } catch (error) {
      setFailedTryLogin(true);
      setIsLogged(false);
    }
  };

  useEffect(() => {
    setFailedTryLogin(false);
    setIsLogged(false)
  }, [cpf, password]);

  if (isLogged.logged) {
    return {
    navigate: navigate('/painel'),
    ...window.location.reload()
    }
    
  }

  return (
    <>
      <Background className="user-login-area">
        <div className='containerCentral'>
        <div className="divImg">
        <img src="https://api.uniscestudantil.org.br/images/000065-01-l.png" alt="logo"/>
        <h2> Seja Bem Vindo! </h2>
        </div>
        <form>
          <div className='areaLogin'> 
          <h1>Área do usuário</h1>
          <label htmlFor="email-input">
            <input
              className="login__login_input"
              type="text"
              value={cpf}
              onChange={({ target: { value } }) => setCpf(cpfMask(value))}
              data-testid="login__login_input"
              placeholder="Login"
            />
          </label>
          <label htmlFor="password-input">
            <input
              type="password"
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
              data-testid="login__password_input"
              placeholder="Senha"
            />
          </label>
          {
            (failedTryLogin)
              ? (
                <p data-testid="login__input_invalid_login_alert">
                  {
                    `O Cpf ou a senha não estão corretos.
                    Por favor, tente novamente.`
                  }
                </p>
              )
              : null
          }
          <button
            type="submit"
            onClick={(event) => login(event)}
          >
            Entrar
          </button>
          </div>
        </form>
        </div>
      </Background>
    </>
  );
};

export default Login;
