import styled from "styled-components";
import {CheckDouble} from "@styled-icons/boxicons-regular";
import {Enter} from "@styled-icons/ionicons-solid";

export const Confirmar = styled(Enter)`
max-width: 20px;
`;

export const ChecarCpf = styled(CheckDouble)`
max-width: 20px;
`;

export const LabelContainer = styled.label`
  margin: 0px;
  padding-left: 5px;
`;

export const InputContainer = styled.input`
  margin: 2px;
`;

export const ButtonCarteira = styled.button`
display: flex;
width: 100px;
align-items: center;
justify-content: center;
position: relative;
top: 17%;
left: -40%;

`;

export const InputImagem = styled.input`
`;

export const Imagem = styled.img`
`;


export const DivCarteira = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .carteiraCriada {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 300px;
  }

  .inputImage {
    position: relative;
    z-index: 1;
    opacity: 0;
    left: 65px;
    top: -25px;
    max-width: 150px;
    height: 180px;
  }

  .image {
    border: 1px solid;
    max-width: 150px;
    max-height: 180px;
    border-radius: 10%;
    position: relative;
    z-index: 0;
    top: -25px;
    left: -65px;
  }
` ;

export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0px 5px 50px;
  align-items: flex-start;
  width: 60%;
  height: 100%;

  a {
    display: flex;
    text-decoration: none;
    width: 80px;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    font-size: 10px;
    border: 1px solid;
    white-space: nowrap;
    position: relative;
    top: 35px;
  }
  `;
