import React from "react";
import EditarDadosUsuario from "../componentes/editarDadosUsuario";


const UserPainel = () => {

  return (
    <EditarDadosUsuario />
  )
}

export default UserPainel;