import React, { useState } from "react";
import FiltrarDados from "../componentes/filtrarDados";
import Carteiras from "../componentes/carteiras";
import Header from "../componentes/header";

const Painel = () => {
  const [filtro, setFiltro] = useState([]);
  const [error, setError] = useState(false);

  return (
    <>
      <Header/>
      <FiltrarDados setFiltro={setFiltro} setError={setError} filtro={filtro} />
      <Carteiras error={error} filtro={filtro} />
    </>
  )
}

export default Painel;