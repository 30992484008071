import React, { useState, useEffect } from "react";
import { requestData } from "../services/requests";
import { arrayEstados, organizarPorEstado } from "../services/funcoesAuxiliares";
import { Global } from "../styled/Gestores";
import Header from './header';
import DeletarGestor from "./Modals/DeletarGestor";
import CadastrarGestor from "./Modals/CadastraGestor";
import DeletarCarteiras from "./Modals/DeletarCarteiras";
import api from "../services/requests";

const VisualizarGestores = () => {
  const [gestores, setGestores] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [gestorId, setGestorId] = useState('');
  const [dataInicial, setDataInicial] = useState('');
  const [idGestor, setIdGestor] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [role, setRole] = useState('');
  const [estado, setEstado] = useState('');
  const [pass, setPass] = useState('');
  const arrayStatus = ["Selecione um Status", "Recepcionada", "Validada", "Renovação", "Expirada", "Cancelada"];
  const newArrayGestores = [{name: 'Selecione um gestor'}, ...gestores];

  const props = {
    email,
    setEmail,
    telefone,
    setTelefone,
    nome,
    setNome,
    cpf,
    setCpf,
    role,
    setRole,
    estado,
    setEstado,
    pass,
    setPass,
  }

  const [exibirModalDeletarGestores, setExibirModalDeletarGestores] = useState(false);
  const [exibirModalCadastrarGestores, setExibirModalCadastrarGestores] = useState(false);
  const [exibirModalDeletarCarteiras, setExibirModalDeletarCarteiras] = useState(false);

  const handleOpenModalDeletarGestores = (id) => {
    setExibirModalDeletarGestores(prev => !prev);
    setIdGestor(id);
  }
  
  const handleOpenModalCadastrarGestores = () => setExibirModalCadastrarGestores(prev => !prev);

  const handleOpenModalDeletarCarteiras = () => setExibirModalDeletarCarteiras(prev => !prev);

  const handleClose = () => { 
    window.location.reload();
  }

  const BloquearGestor = async (id, bloqueio) => {
    try {
      await api.put(`/user/bloquear/${id}`, { bloqueio });
      alert('Gestor bloqueado!');
      window.location.reload();
    }
    catch (e) {
      console.log(e);
    }
  }

  const DesbloquearGestor = async (id, desbloqueio) => {
    try {
      await api.put(`/user/desbloquear/${id}`, { desbloqueio });
      alert('Gestor desbloqueado com sucesso!');
      window.location.reload();
    }
    catch (e) {
      console.log(e);
    }
  }

  const excluirGestor = async (id) => {
    try {
      await api.delete(`/user/deletar/${id}`);
      window.location.reload();
      setExibirModalDeletarGestores(prev => !prev);
    } catch (error) {
      console.log(error);
    }
  }

  const deletarCarteiras = async (dataInicial, dataFinal, status, gestorId ) => {
    try {
      if (dataFinal !== '') {
        await api.delete(`/carteiras/deletar/data/?dataInicial=${dataInicial}&dataFinal=${dataFinal}`);
        alert('Carteira excluida com sucesso!');
        window.location.reload();
      }
      if (status !== '') {
        await api.delete(`/carteiras/deletar/status/?status=${status}`);
        alert('Carteira excluida com sucesso!');
        window.location.reload();
      }
      if (gestorId !== '') {
        await api.delete(`/carteiras/deletar/gestor/?id=${gestorId}`);
        alert('Carteira excluida com sucesso!');
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  
  }

  const cadastroGestores = async (nome, pass, email, cpf, telefone, role, estado) => {
    try {
      await api.post('/user/cadastrar/gestor', {
        nome,
        pass,
        email,
        cpf,
        telefone,
        role,
        estado,
      });
      setExibirModalCadastrarGestores(prev => !prev);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  const obterGestores = async () => {
    setGestores(await requestData('/user'));
  }

  useEffect(() => {
    obterGestores();
  }, []);

  const gestoresOrganizados = organizarPorEstado(arrayEstados, gestores);
  const location = window.location.href.split('/')[3];

  return (
    <>
    <Header location={location}/>
    <Global>
      <div className="deleter-carteiras">
        <div className="titulo-deletar">
          <h2>Excluir Carteiras</h2>
        </div>
        <div className="card-opcoes">
          <div className="card-deletar">
            <span> Por Data </span>
            <label>
              <input
              value={dataInicial}
              onChange={({target: {value}}) => setDataInicial(value)}
              type="date" />
              <span> Até </span>
              <input
              value={dataFinal}
              onChange={({target: {value}}) => setDataFinal(value)}
              type="date" />
            </label>
          </div>
          <div className="buttons">
            <button
              className="excluir"
              onClick={handleOpenModalDeletarCarteiras}> DELETAR </button>
          </div>
        </div>
        <div className="card-opcoes">
          <div className="card-deletar">
            <span> Por Status </span>
            <label>
              <select onChange={(e) => setSelectedStatus(e.target.value)}>
                {arrayStatus.map((status, index) => (
                  <option key={index + 1} value={status}> {status} </option>
                ))}
              </select>
            </label>
          </div>
          <div className="buttons">
            <button
              className="excluir"
              onClick={handleOpenModalDeletarCarteiras}> DELETAR </button>
          </div>
        </div>
        <div className="card-opcoes">
          <div className="card-deletar">
            <span> Por Gestor </span>
            <label>
              <select onChange={(e) => setGestorId(e.target.value)}>
                {newArrayGestores.map((gestor, index) => (
                  <option key={index} value={gestor.id}> {gestor.name} </option>
                ))}
              </select>
            </label>
          </div>
          <div className="buttons">
            <button
              className="excluir"
              onClick={handleOpenModalDeletarCarteiras}> DELETAR </button>
          </div>
        </div>
        <DeletarCarteiras 
        show={exibirModalDeletarCarteiras} 
        handleOpen={handleOpenModalDeletarCarteiras} 
        deletarCarteiras={deletarCarteiras}
        gestorId={gestorId}
        status={selectedStatus}
        dataFinal={dataFinal}
        dataInicial={dataInicial}
        handleClose={handleClose}/>
      </div>
      <div className="gestores">
        <div className="gestores-titulo">
          <h2>Gestores</h2>
          <button
            className="bttn-cadastrar-gestores"
            onClick={handleOpenModalCadastrarGestores}> CADASTRAR GESTOR </button>
            <CadastrarGestor show={exibirModalCadastrarGestores} handleClose={handleClose} props={props} cadastroGestores={cadastroGestores}/>
        </div>
        <div className="deletar-gestores">
          {gestoresOrganizados.map((e, index) => (
            (Object.values(e).length !== 0) ?
           <div key={index + 1}>
              <div className="card-global" key={index}>
                <h3 className="card-titulo">
                  {Object.keys(e)}
                </h3>
                {Object.values(e).map((h) => h.map((j) =>
                  <div key={j.id} className="card-gestores">
                    <span> {j.name} </span>
                    <div className="card-buttons">
                      {j.bloqueio === true ? 
                      <button className="desbloquear" onClick={() => DesbloquearGestor(j.id, false)}> DESBLOQUEAR </button> :  
                      <button className="bloquear" onClick={() => BloquearGestor(j.id, true)}> BLOQUEAR </button>
                    }
                      <button className="excluir" onClick={() => handleOpenModalDeletarGestores(j.id)}> DELETAR GESTOR </button>
                    </div>
                  </div>
                ))}
              </div> 
              <DeletarGestor show={exibirModalDeletarGestores} handleClose={handleClose} handleOpen={handleOpenModalDeletarGestores} deletar={excluirGestor} id={idGestor} />
              </div>: null     
          ))}
        </div>
      </div>
    </Global>
    </>
  )
}

export default VisualizarGestores;
