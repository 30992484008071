import React from 'react';
import PaginaPrincipal from '../componentes/paginaPrincipal';

const Principal = () => {
  return (
    <>
    <PaginaPrincipal />
    </>
  )
}

export default Principal;