import styled from "styled-components";
import {Edit} from '@styled-icons/material-outlined'
import {Open} from '@styled-icons/fluentui-system-regular'
import {Copy} from "@styled-icons/ionicons-outline";
import {Autorenew} from "@styled-icons/material";

export const Renovar = styled(Autorenew)`
max-width: 20px;

`;

export const Copiar = styled(Copy)`
max-width: 20px;
`;

export const Table = styled.table`
display: flex;
flex-direction: column;
`;

export const Thead = styled.thead`

tr {
  background: rgb(231,244,255);
  display: flex;
  height: 85px;
  justify-content: space-around;
  align-items: center;
  margin: 8px;
  padding: 5px;
}

th {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 13px;
  padding-left: 5px;
  border-left: 1px solid #1192c989;
  height: 100%;

  p {
    margin: 0px;
  }
}
`;

export const Tbody = styled.tbody`
margin-top: -5px;
`;

export const CarteiraContainer = styled.tr`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  margin: 8px;

  &:hover {
    background-color: #e5eef5;
  }

#tabela {
  width: 100%;
  margin-top: -8px;
  background-color: #e5eef5;
  justify-content: space-around;
}`;

export const DivCarteiras = styled.td`
  background: #e9eff8;
  display: flex;
  height: 100px;
  justify-content: space-around;
  width: 99.6%;
  align-items: center;
  margin: 8px;
  padding: 1px;

  .dataEmissao {
    font-size: 14px;
  }

td {
  display: flex;
  min-width: 0;
  flex-basis: 50%;
  flex-direction: column;
  border-left: 1px solid #1192c989;
  padding: 10px;
  width: 100%;
  height: 80%;
  justify-content: center;

  .dataValidade {
    font-size: 14px;
  }
  
  .img_aluno {
    max-width: 100px;
    max-height: 100px;
  }

  p {
    margin: 2px 2px 2px 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    span {
      display: none;
    }

    :hover {
      span {
      transition-delay: 2s;
      overflow: unset;
      height: 20px;
      padding: 5px;
      left: 30%;
      text-overflow: unset;
      white-space: unset;
      position: absolute;
      color: white;
      border: none;
      background-color: #626161;
      display: block;
      }

    }

  }

  .botaoVisualizar {
    color: white;
    text-decoration: none;
    background-color: #3889f4;
    border-radius: 5px;
    max-width: 100px;
    font-family: cursive;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin:2px;

    :hover{
      border: 1px solid;
    }
  }

  .botaoEditar {
    color: white;
    text-decoration: none;
    background-color: #77c244;
    font-family: cursive;
    border-radius: 5px;
    max-width: 100px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin:2px;

    :hover{
      border: 1px solid;
    }

    
  }

  .copy {
    color: white;
    text-decoration: none;
    background-color: orange;
    font-family: cursive;
    border-radius: 5px;
    max-width: 100px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin:2px;

    :hover{
      border: 1px solid;
    }
  }

  .popUp {
    color: white;
    text-decoration: none;
    background-color: #ff020285;
    font-family: cursive;
    border-radius: 5px;
    max-width: 100px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin:2px;

    :hover{
      border: 1px solid;
    }
  }

  .renovacao {
    color: white;
    text-decoration: none;
    background-color: #db3636;
    border-radius: 5px;
    max-width: 100px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin:2px;

    :hover{
      border: 1px solid;
    }
  }

}`;

export const Abrir = styled(Open)`
max-width: 20px;
`;

export const Editar = styled(Edit)`
max-width: 20px;
`;

export const Container = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
position: relative;
width: 1150px;
left: 90px;
`;

export const Container3 = styled.td`
display: none;
width: 1000px;
`;

export const ContainerRenovacoes = styled.div`
  display: flex;
  flex-direction: column;
  border-color: pink;
  border: 1px solid;
`;

export const DivRenovacoes = styled.div`
padding: 5px;
`;

export const Container2 = styled.div`
border: 5px solid;
display: flex;
width: 1000px;
`;

export const Resultados = styled.div`
margin-left: 20px;
`;



