import React from "react";
import VisualizarGestores from "../componentes/VisualizarGestores";

const Gestores = () => {
  return (
  <VisualizarGestores />
  )
}

export default Gestores;
